/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@fortawesome\fontawesome-free\css\all.min.css";





/* Border Color */
.ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: red;
}

/* Arrow Color */
.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
    border-color: red transparent transparent;
}

/* Placeholder Color */
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
    color: red;
}

input.ng-invalid.ng-touched {
    border-color: red !important;
}

.checkBoxLg {
    height: 30px;
    width: 30px;
}

/* TODO: ova dali treba */
/* ngb-modal-window .modal-dialog.modal-xl {
    max-width: 100px !important; 
  } */

.mar-top-25 {
    margin-top: 25px;
}

/* ng select in form control */
ng-select {
    padding-right: 0 !important;
}

.customInputGroup .ng-select .ng-select-container {
    border: 0;
    border-radius: 0 !important;
}

.mTop-15 {
    margin-top: 15px;
}

.pad-0 {
    padding: 0;
}

.pBot-0 {
    padding-bottom: 0 !important;
}

.pTop-5 {
    padding-top: 5px;
}

.pTop-15 {
    padding-top: 15px;
    border-radius: 5px;
}

.noPadding {
    padding: 0 !important;
}

.noPaddingLeft {
    padding-left: 0 !important;
}

.noBorderRadius {
    border-radius: 0 !important;
}

.w-125 {
    width: 125px;
    height: 38px;

}

.vertText {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.btnAlignCenter {
    text-align: center;
}

/*context menu*/
#context {
    position: fixed;
    /* z-index:10000; */
    width: 50px;

}

#context.item {
    padding: 2px 2px;
    font-size: 5px;

}

/*table badge*/
.badge {
    font-size: 12px;
}

.table tr.active td {
    background-color: #a6a6a6 !important;
    color: white;
}

.ButtonsHolder {
    padding-left: 0;
}


table>tbody>tr>td {
    vertical-align: middle;
}

/*paginator*/
ngb-pagination ul>li:not(.active)>a {
    background-color: #ecfef7 !important;
}

ngb-pagination ul>li.active>a {
    background-color: #33ccff !important;
}

/*searchField*/
#search {
    position: end;
    size: 50%;
    border-radius: 25px;
}

.Edit:hover {
    color: #B2FAAB;
}

.Delete:hover {
    color: red;
}

.Top {
    padding-bottom: 30px;
}

.Search {
    padding-left: 0px
}

.dragableList {
    width: 500px;
    max-width: 100%;
    border: none;
    min-height: 60px;
    display: block;
    background: #f4f8ff;
    border-radius: 4px;
    overflow: hidden;
}

.dragableBox {
    padding: 20px 10px;
    border: solid 1px #f4f8ff;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background: #f4f8ff;
    font-size: 14px;
    margin-top: 15px;
}

.dragableHandle {
    color: rgb(23, 26, 201);
    cursor: move;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.dragableList.cdk-drop-list-dragging .dragableBbox:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.borderStop {
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 1px;
    border-style: solid;
}

.borderStop:first-child {
    border-left: 1px;
    border-style: solid;
}

.borderStop:last-child {
    border-right: 1px;
    border-style: solid;
}

form .card .card-body .row {
    display: flex;
    flex-direction: column;
    position: relative;

    height: 80px;
    width: 100%;

}

.card {
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
}

.card-body {
    padding-right: 0;
    padding-left: 28px;
    padding-top: 30px;
}

.card .card-body.companyBol {
    position: relative;
    height: 100%;
    width: 100%;

}

.companyBol nb-form-field {
    position: relative;
    height: 100%;
    width: 100%;

}

.companyBol nb-form-field input {
    width: 100%;
    height: 100%;
    font-size: 16px;
}

.companyBol input {
    width: 100%;
    height: 100%;
    font-size: 16px;
}

.companyBol textarea {
    width: 100%;
    height: 100%;
    font-size: 16px;
}

.companyBol .select {
    font-size: 16px;
}

.placeholder {
    position: absolute;
    left: 12px;

    top: calc(50%+10px);
    transform: translateY(-145%);
    transition:
        all 1s ease;
}

.companyBol input:valid+.placeholder,
.companyBol input:focus+.placeholder {
    top: 10px;
    font-size: 13px;
}

.companyBol .select.ng-select.ng-valid+.placeholder,
.companyBol .select.ng-select-focused+.placeholder {
    top: 10px;
    font-size: 13px;
}

.companyBol textarea:valid+.placeholder,
.companyBol textarea:focus+.placeholder {
    top: 10px;
    font-size: 13px;
}


::ng-deep .ng-select-container {
    height: 40px !important;
    font-size: 0.8em !important;
}

.AddButton {
    padding-top: 2px;
}

.selectCarrier {
    padding-right: 0px;
}

nb-tabset {
    background-color: #f4f8ff;
}

.NotePlace {
    padding-left: 10px;
}